document.getElementById("reveal-email")?.addEventListener("click", ev => {
    const emailElement = document.createElement("a")

    // This bit prevents bots from scraping my email address without first running JavaScript
    const emailAddrReversedB64 = "==QbvNmLsxWay" + "J3bt5WYpBUZt5CdjFGdu" + (46 * 2) + "Y"
    const emailAddr = atob(Array.from(emailAddrReversedB64).reduce((a, b) => b + a, ""))

    emailElement.href = "mailto:" + emailAddr
    emailElement.textContent = emailAddr
    document.getElementById("reveal-email")?.parentElement?.appendChild(emailElement)
    document.getElementById("reveal-email")?.remove()
})